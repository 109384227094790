import Head from 'next/head';
import Link from 'next/link';
import React from 'react';

const Home = () => {
  return (
    <React.Fragment>
      <Head>
        <title>Wheel Strategy Tools | wheelstrategytools.com | options wheel strategy</title>
        <meta
          name="description"
          content="Wheel Strategy Tools | wheelstrategytools.com | options wheel strategy"
          key="desc"
        />
        <meta
          property="og:description"
          content="Wheel Strategy Tools | wheelstrategytools.com | options wheel strategy"
        />
      </Head>
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-9">

            <img className="wrapimage" src="/images/wheelrotation_withtext.svg" alt="Wheel Strategy Tools" />
            <p>As a buy and hold investor, you know the value of long term investments that can grow over time.
              But what if there was a way to boost your returns while maintaining your long term focus? The <b>Wheel Strategy</b> is a tool that a buy and hold investor can use to complement capital growth with income, including making money
              in sideways trending markets.
            </p>

            <p>There are three levels levels of access:</p>
            <ul className="nobullets">
              <li>
                - <i><b>Guest access</b></i> is free and lets you read articles and learn about the site without establishing a login.
              </li>
              <li>
                - <i><b>Registered access</b></i> is free, requires establishing a login and gives you Guest access plus the ability to run historical simulations.
              </li>
              <li>
                - <i><b>Member access</b></i> is paid and gives full access to the site, including real time suggestions and tools for portfolio tracking.
                Member access is $39.95 per month plus tax.
              </li>
            </ul>

            <p>We didn&apos;t invent the Wheel Strategy. It&apos;s been around for a long time and there are plenty of resources to help you learn
              about it, including a number of <Link href="/articles">articles</Link> on this site.  A good starting point is this introduction <Link href="/articles/0314b526-a945-4925-ad14-e3440bf2e71d/">An Introduction To The Wheel Strategy</Link>
            </p>
            <p>
              <b>The reason that <Link href="/">wheelstrategytools.com</Link> exists</b> is that applying the strategy gets
              a lot easier when you have some tools:
            </p>
            <ul className="nobullets">
              <li>
                1) A system that will automatically filter through the hundreds or thousand of option choices (for each stock or ETF) to find those with the best return.
              </li>
              <li>
                2) Knowing when the money or stock tied up by an option can be better used by buying to cover
                current held options and selling options that will give you a better return.
              </li>
              <li>
                3) An automated way to optimally allocate your positions over multiple accounts, tracking expenses and returns.
              </li>
              <li>
                4) A way to back test your choices (e.g. stocks chosen, parameters optimized, fees controlled) over historical data to see how they would have fared in the past.
              </li>
              <li>
                5) An easy way to get suggestions for appropriate trades for you to evaluate.
              </li>
            </ul>

            <p>
              Think of <Link href="/home">wheelstrategytools.com</Link> like a power tool for the Wheel strategy.  With enough time and effort
              you could do it all by hand or with spreadsheets, just as you could use a hand saw rather than a power saw to both do the same
              job. Having appropriate tools lets you focus on strategy and results rather than slogging through the mechanics of the process.
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Home